<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>Profile</h3>
        <button class="btn btn-control" @click="$emit('close')">
          <img src="@/assets/icons/icon-close.svg" alt="">
        </button>
      </div>
      <div class="modal-body">
        <page-spinner v-if="ui.loading" />
        <div v-else>
          <div class="modal-body-container mb-0">
            <div class="profile-info">
              <div class="profile-logo">
                <img :src="agentPhoto(agent.photoUrl)" alt="Agent Avatar" />
              </div>
              <div class="profile-info-body">
                <div class="profile-info__name">{{ agent.fullName }}</div>
                <div class="profile-info__position">{{ agent.title }}</div>
                <div class="d-flex">
                  <a :href="'tel:' + agent.phone">Call</a>
                  <a :href="'mailto:' + agent.email">Email</a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-body-container mb-0 border-0 pb-0 mt-3">
            <div class="modal-body-container--title">About</div>
            <div class="table-block">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Position</td>
                    <td>{{ agent.title }}</td>
                  </tr>
                  <tr>
                    <td>Branch</td>
                    <td>{{ agent.branch }}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{{ formatUSNumber(agent.phone) }}</td>
                  </tr>
                  <tr>
                    <td>Email Address</td>
                    <td>{{ agent.email }}</td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>{{ formatUSNumber(agent.fax) }}</td>
                  </tr>
                  <tr>
                    <td>NMLS</td>
                    <td>{{ agent.nmls }}</td>
                  </tr>
                  <tr v-if="!hideSections">
                    <td>Hired Date</td>
                    <td>{{ agent.hiredDate }}</td>
                  </tr>
                  <tr v-if="!hideSections">
                    <td>Hired by</td>
                    <td>{{ agent.hiredBy }}</td>
                  </tr>
                  <tr v-if="hideSections">
                     <td>States</td>
                    <td>{{ agent.states }}</td>
                  </tr>
                  <tr v-if="!hideSections">
                    <td>License States</td>
                    <td>{{ agent.states }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-body-containter"  v-if="!hideSections">
            <div class="modal-body-container--title">Links</div>
            <div class="table-block">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Website</td>
                    <td>
                      <a href=""> https://nathankowarsky.com </a>
                    </td>
                  </tr>
                  <tr>
                    <td>CMC Profile</td>
                    <td>
                      <a :href="agent.cmcProfile">
                        {{ agent.cmcProfile }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Apply URL</td>
                    <td>
                      <a :href="agent.applyURL">
                        {{ agent.applyURL }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Rate Quote URL</td>
                    <td>
                      <a :href="agent.rateQuoteURL">
                        {{ agent.rateQuoteURL }}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Calendly URL</td>
                    <td>
                      <a :href="agent.calendlyURL">{{ agent.calendlyURL }}</a>
                    </td>
                  </tr>
                  <tr>
                    <td>Secure Documents URL</td>
                    <td>
                      <a :href="agent.documentsURL">
                        {{ agent.documentsURL }}
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageSpinner from "../../../components/pageSpinner.vue";
export default {
  components: { pageSpinner },
  props: {
    id: {
      type: Number,
      default: 0,
      required: true,
    },
    hideSections: {
      type: Boolean,
      default: false,
    }
  },
  name: "ProfileModal",
  data: () => ({
    ui: {
      loading: false,
    },
    agent: {},
  }),
  beforeMount() {
    this.getData();
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http
        .get(`/api/v1/staff/profile/${this.id}/get-details`)
        .then((res) => {
          this.agent = res.data;
          this.ui.loading = false;
        })
        .catch(() => {
          this.pushAlert("error", "Something went wrong!");
          this.ui.loading = false;
          this.$emit("close");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-body-container--title {
  width: 44px;
  color: #000000;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: 36px;
  margin-bottom: 18px;
}
.table-block {
  table {
    white-space: pre-line;
    tbody {
      tr {
        &:nth-child(even) {
          td {
            border-color: rgba(0, 0, 0, 0.2) !important;
          }
        }
        td {
          word-break: break-all;
          &:first-child {
            width: 30% !important;
            border-radius: 0;
          }
          &:last-child {
            border-radius: 0;
          }
        }
      }
    }
  }
}
.modal {
  .modal-block {
    .profile-info {
      display: flex;
      .profile-logo {
        img {
          height: 110px;
          width: 110px;
          border: 1px solid #cccccc;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      &-body {
        margin-left: 24px;
      }
      &__name {
        color: #000000;
        font-size: 24px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
      }
      &__position {
        opacity: 0.7;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        margin: 12px 0 16px 0;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        height: 32px;
        border: 1px solid #8a8a8a;
        border-radius: 8px;
        color: rgba($color: #000000, $alpha: 0.7);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
        padding: 0 11px;
        margin-right: 8px;
      }
    }
  }
}
</style>
